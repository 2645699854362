<template>
  <div title="PrivacyPolicy" style="background-color: #eff3f5;height: 100%;width: 100%;overflow: scroll" align="center" overflow="scroll">
    <a-card style="background-color: white;width: 1100px;margin-top: 50px;padding-left: 20px;padding-right: 20px">
      <div style="margin-top: 20px">
        <h3>
          <p style="font-size: 26px">Privacy Policy for {{ themeData.saasMerNameEn }} Services</p>
        </h3>
      </div>
      <div align="left" style="margin-top: 50px">
        <div>
          <p>
            Your privacy is important to us. To better protect your privacy, {{ themeData.saasMerNameEn }} (“{{ themeData.saasMerNameEn }}”, “we”, “us”, “our”) developed this Privacy Policy to demonstrate our firm commitment to protecting your privacy.<br>
This privacy policy has been developed in accordance with the Personal Data (Privacy) Ordinance (Chapter 486) of the Laws of the Hong Kong Special Administrative Region (“Ordinance”), and applies to all visitors of our Website and Apps and users of our products and services.<br>
At {{ themeData.saasMerNameEn }} we have a few fundamental principles:<br>
• We won’t ask you for personal information unless we truly need it.<br>
• We won’t share your personal information with anyone except to comply with the law, provide our services, or protect our rights.<br>
• We restrict access to your personal information to employees and service providers who need to know that information to provide services to you.<br>
• We maintain physical, electronic and process security protocols to protect your personal information.<br>
This Privacy Policy, together with our Union Goldenpay (HK) Limited Services Terms and Conditions, governs Union Goldenpay (HK) Limited’s collection, processing and use of your Personal Information. As used in this Privacy Policy, "Union Goldenpay (HK) Limited" refers to Union Goldenpay (HK) Limited Group Companies, including, without limitation, its owners, directors, investors, employees or other related parties. "Personal Information" refers to information that identifies an individual, such as name, address, e-mail address, trading information, and banking details. “Personal Information” does not include anonymized and/or aggregated data that does not identify a specific user.<br>

By entering into the Agreement and the Terms and Conditions, you are specifically accepting the present Union Goldenpay (HK) Limited Privacy Policy and consenting to the collection, processing and communication of your data as described in this Union Goldenpay (HK) Limited Privacy Policy.<br>

The purpose of this Privacy Policy is to describe:<br>
1.When do we collect personal information;<br>
2.How do we collect personal information;<br>
3.The types of Personal Information we collect and how it may be used;<br>
4.Our use of cookies and similar technology;<br>
5.How and why we may disclose your Personal Information to third parties;<br>
6.The transfer of your Personal Information within and outside of your home country;<br>
7.Your right to access, correct, update, and delete your Personal Information;<br>
8.How can we keep your personal information secure;<br>
9.How long will we keep your personal information;<br>
10.How do you access your personal information held by us.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">I. Introduction</p>
          <p>In order to be able to make Union Goldenpay (HK) Limited’s services available to you in compliance with the obligations imposed by Applicable Law and by regulatory authorities, Union Goldenpay (HK) Limited must ask you for certain information about yourself, including financial information (e.g., information about your identity, e-commerce sales, beneficiary account, etc.). Union Goldenpay (HK) Limited will utilize the data provided by you in accordance with applicable provisions of governing data protection laws. The information obtained shall, as a matter of principle, be used by Union Goldenpay (HK) Limited solely for providing Union Goldenpay (HK) Limited Services and solely to the extent necessary to render such services. The information about you which we store shall, as a matter of principle, not be made accessible to third parties unless we are obligated to do so by law or due to legal ordinances or in order to properly render our services to you. We will always inform you when we are collecting such information. It is our intention to respect your request for privacy, and we try our best to provide you with communication channels to facilitate this. Once all gathered information has been used for the intended purpose, it is destroyed or de-identified.<br>

The following discloses our information gathering methods and dissemination practices for our Website and Apps. Union Goldenpay (HK) Limited is not responsible for the privacy practices of other linked sites.</p>
        </div>
        <div>
          <p class="navigationTitle">
            II. When do we collect personal information?
          </p>
          <p>
            When you visit our website: www.sprout-pay.com (“Website”) or mobile applications or APIs (collectively, the “Apps”), our server software records the domain name of your computer or device. We may also track the pages you visit. We do these things so that we can measure traffic, gauge the popularity of various parts of the Website and Apps, gain some general knowledge about our audience and compile aggregated statistics. We may also use cookies to collect information on how you use our Website and Apps. If you refuse cookies, certain features of our Website and Apps may not be available to you. For further information, please refer to our Cookies related policy.<br>
If you wish to register for our products and services, we will from time to time collect your personal information specifically for the purpose of assessing your applications to use (or continual usage of) services or products offered by us. If you provide incomplete or incorrect personal information, we may not be able to provide or continue to provide our products and services to you.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">III. How do we collect personal information?</p>
          <p>A. Collected from Website Visitors<br>
Like most website operators, Union Goldenpay (HK) Limited automatically collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Union Goldenpay (HK) Limited’s purpose in collecting non-personally identifying information is to better understand how Union Goldenpay (HK) Limited’s visitors use its website, through cookies, web beacons, log files and other technologies:<br>
Your domain name, your browser type and operating system, web pages you view, links you click;<br>
(2) Your IP address, the length of time you visit our Website or use our Services, your activities on our Website, and the referring URL or the webpage that led you to our Website.<br>
Please see Section 11 “Cookies” below for more information. Visitors can always refuse to provide personally-identifying information, with the caveat that it may prevent them from being able to use Union Goldenpay (HK) Limited’s services.<br>
B.Provided by Users<br>
You may provide us data about you by filling in forms on our website (e.g. Union Goldenpay (HK) Limited Account Registration), or by contacting with us (for example, by e-mail or telephone). The data you provide may include data about additional persons that are beneficial owners of your company. This includes that is provided to us about you:<br>
(1)upon registration for a Union Goldenpay (HK) Limited Account;<br>
(2)when you log in to your Union Goldenpay (HK) Limited Account;<br>
(3)when you submit any Settlement Orders through your Union Goldenpay (HK) Limited Account;<br>
(4)when entering a competition, promotion or survey;<br>
(5)when a problem is reported or a request for support is received.<br>
Visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from being able to use Union Goldenpay (HK) Limited’s services.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">IV. Information we collect</p>
          <p>We collect the Personal Information you provide directly to us when you apply for a Union Goldenpay (HK) Limited Account, perform any transactions on the Union Goldenpay (HK) Limited platform, or use other Union Goldenpay (HK) Limited Services. This may include:<br>
1.Your contact information (e.g., name, email address, phone number, billing or mailing address);<br>
2.Bank and credit account information;<br>
3.IP address;<br>
4.Identity verification (e.g., photograph, other information requested to verify your information, including copy of valid ID document);<br>
5.Publicly available and/or criminal history;<br>
6.National identification numbers;<br>
7.Nationality;<br>
8.Date and place of birth;<br>
9.Details of any transactions carried out using any the services;<br>
10.Any other information that you choose to provide to us (e.g., if you send us an email/otherwise contact us);<br>
11.information obtained from mobile device with your consent (e.g. device location and information);<br>
12.your email and marketing preferences including interests and market list assignments, marketing objections, preferred language, Website and Apps data; and<br>
13.Calls/emails/other correspondence;<br>
14.employment details (e.g. occupation, directorships and other positions held, employment history, salary, and/or benefits);<br>
15.tax information;<br>
16.background checks information (e.g. credit history);<br>
17.information you provide to us when you contact our support team to help us answer your queries; and<br>
18.Information through Cookies and other tracking technologies as listed above and as described in the section below entitled “Cookies”.<br>
You are responsible for providing accurate and up-to-date information.<br>
If you are required to provide information about shareholders or beneficial owners of your business, you acknowledge that you have that person’s consent to provide his/her information to us. This may include:<br>
1.Contact information, such as name, home address, and email address.<br>
2.Account information, such as username and credential.<br>
3.Financial information, such as bank account numbers, bank statement, and trading information.<br>
4. Identity verification information, such as images of your government issued ID, passport, national ID card, or driving license. Note: US residents may be asked to provide their social security cards.<br>
5. Residence verification information, such as utility bill details or similar information.<br>
We may collect and process personal information about buyers or third parties related to your b<br>usiness in providing Union Goldenpay (HK) Limited Services. You are responsible for making sure that the privacy rights of any third parties, including buyers and other individuals related to your business, are respected, including ensuring appropriate disclosures about third party data collection and use; with respect to such data you hereby (i) are deemed to be and (ii) accept to be controller. To the extent that we are acting as your data processor, we will process Personal Data in accordance with the terms of our agreement with you and your lawful instructions.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">V. How we use your data?</p>
          <p>We will only use your Personal Information to:<br>
1. create customer profiles, maintain customer records and to communicate with you (including providing you with updates on changes to products and services), fulfil your requests and respond to your queries or feedback, addressing or investigating any complaints, claims or disputes;<br>
2.send you important notices or personalized messages in relation to your use of our products and services;<br>
3.administer the operation of our Website and Apps, to allow you to use the services and functions of our Website, to conduct website maintenance, to troubleshoot problems, and to continually improve your Website experience and provide you with services and products in accordance with the terms and conditions that govern our relationship with you;<br>
4.to identify and send you marketing materials, news and information which we think will be of interest to you such as our latest promotional offers and upcoming products and services;<br>
5. conducting screening, due diligence checks and verify your identity for the purposes of providing our products or services as may be required under applicable laws, regulations or directives;<br>
6. detect, investigate and prevent fraud and other illegal activities;<br>
7. use your personal information for purposes associated with our legal or regulatory rights and obligations, including financial reporting, regulatory reporting, management reporting, risk management (including monitoring credit exposures), audit and record keeping purposes and for the purposes of seeking professional advice, including legal advice; <br>
8. keep internal records, decision making and facilitate internal purposes such as data analysis, and research to improve our products and services, service security and quality, and to maintain opt-in and opt-out lists;<br>
9. process your transactions, including transfers into and/or out of the People’s Republic of China in accordance with People’s Bank of China and/or State Administration of Foreign Exchange regulations; and<br>
10. Contact you about Union Goldenpay (HK) Limited Services. <br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">VI. When will we use personal information for direct marketing purposes?</p>
          <p>1.Where you have given your explicit consent and have not subsequently opted out, we may use your personal data, including your contact information and demographic information to send marketing and promotional purposes to you. For example, we may use your personal data to offer you products or services related company news, including updates, special offers, promotions, entitlements or related information that may be of interest to you or for which you may be eligible. Such marketing messages may be sent to you in various modes including but not limited to electronic mail, direct mailers, short message service, telephone calls, facsimile and other mobile messaging services. You may at any time request that we stop contacting you for marketing purposes via selected or all modes.<br>
2.Administer a contest, promotion, survey or other site features as will be more explained on the website.<br>

To find out more on how you can change the way we use your personal data for marketing purposes, please contact us (please see the “How to contact us” section below).<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">VII. Who do we share your personal information with?</p>
          <p>Your personal information may be shared with our affiliated companies in providing our products and services to you. We may also disclose your personal information to the following third parties for the purposes described here:<br>

1.Service providers and partners we engage in order to provide our products and services to you, such as payment processing companies, acquirers, bank partners, payment system operators to process your transactions.<br>
2.In response to a valid legal request from government, regulatory authorities, law enforcement agencies and courts.<br>
3.Professional advisors such as law firms to enforce or apply any contract with you or including subpoenas, court orders, legal process, and our auditors in connection with our audit requirements.<br>
4.Service providers and partners to carry out due diligence identity verification, transactions monitoring, fraud-screening and for the purposes of detecting, preventing or otherwise addressing fraud, security or technical issues, protecting against harm to the rights, property or safety of our users or the public, or as necessary to serve our legitimate business interests.<br>
5.In order to improve our business services, we may share your personal information, as well as non-personally identifiable information, with our advertising and analytic providers and with related service providers.<br>
6.We may share your personal data with any subsidiaries, joint ventures, or other companies under common control. If another company acquires our company, business, or our assets, that company will possess the personal data collected by us and will assume the rights and obligations regarding your personal data as described in this Privacy Policy.<br>

For more information about the third parties with whom we share your personal data, you may, where appropriate, wish to refer to the agreement(s) and/or terms and conditions that govern our relationship with you. You may also contact us for more information (please see the “How to contact us” section below).<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">VIII. How can we keep your personal information secure?</p>
          <p>Our services are global and data may be stored and processed in any country where we have operations or where we engage service providers. Data we collect may be transferred to and/or stored at a destination outside your country of residence or the Account Jurisdiction, which may have data protection rules that are different from those of your country, including transferring data to and from regulatory authorities, or to staff operating outside the country who process data on our behalf or for one of our suppliers. Staff may be engaged in the fulfillment of your request and the provision of support services. However, we will take measures to ensure that any such transfers comply with applicable data protection laws and that your data remains protected to the standards described in this privacy policy. By submitting the data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that the data is treated securely and in accordance with this Privacy Policy and the relevant data protection regulations.
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">IX. How long will we keep your personal information?</p>
          <p>Your personal data is retained as long as the purpose for which it was collected remains and until it is no longer necessary for any other legal or business purposes.<br>

To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">X. How do you access your personal information held by us?</p>
          <p>Subject to and in accordance with applicable data protection laws, you may access certain personal data held by us about you and correct any inaccuracies. We may charge a fee for processing your request for access. Such a fee depends on the nature and complexity of your access request. Information on any processing fees will be made available to you.<br>

Please contact us (please see the “How to contact us” section below) for details on how you may request access or correction to, or exercise your rights with respect to the processing of your personal data.
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">XI. Protection of Certain Personally-Identifying Information</p>
          <p>Union Goldenpay (HK) Limited takes all organizational and technical measures appropriate to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.<br>

The data you provide to us will accessible only to our authorized employees and contractors and those to whom we give access.<br>

All data that you provide to us is stored on our secure servers. You are responsible for keeping your account credentials safe and secure and not sharing them with anyone.<br>
The transmission of information via the internet is not completely secure; any transmission is at your own risk. Although no one can guarantee the security of data transmitted via the internet, we do our best to protect the data transmitted via the Union Goldenpay (HK) Limited Account. We use industry standard security techniques to help keep the data safe including encryption when the data is in transit and at rest.<br>

The Union Goldenpay (HK) Limited website may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any Personal Information to these third parties.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">XII. Cookies</p>
          <p>To make this site work properly, Union Goldenpay (HK) Limited, similar to many other major websites, sometimes use small data files called cookies or other tracking technology to track information about your use of our website and services. We may use third party service providers to collect this information on our behalf.<br>
A. What are cookies?<br>
A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. It enables the website to remember your actions and preferences (such as login, language, font size and other display preferences) over a period of time, so you don’t have to keep re-entering them whenever you come back to the site or browse from one page to another.<br>
B. How we use cookies and tracking mechanisms.<br>
Union Goldenpay (HK) Limited’s website uses the following cookies/tracking mechanisms.<br>
• Session cookies. Session cookies are temporary cookies that remain in the cookie file of your browser until you leave the site. We use session cookies to allow you to carry information across pages of our site and avoid having to re-submit the same information. The cookies will be deleted after your web browser has been closed.<br>
• Persistent cookies. Persistent cookies remain in the cookie file of your browser for much longer (though how long will depend on the lifetime of the specific cookie). We use persistent cookies:<br>
• To help us recognize you as a unique visitor when you return to our website and to monitor your use of our website;<br>
• To allow us to link you to any of our Partners of Affiliates should you come to our website through a paid advert or banner on a website of an Affiliate or Partner.<br>
• The cookies will be deleted based on their own expiration period after your web browser has been closed.<br>
• Web beacons. Some of our web pages may contain web beacons which allow us to count users who have visited these pages. Web beacons collect only limited information including a cookie number, time and date of a page view, and a description of the page on which the web beacon resides. These beacons do not carry any personally identifiable information and are used to track the effectiveness of a particular marketing campaign.<br>
C.How to control cookies<br>
You can control and/or delete cookies as you wish – for details, see aboutcookies.org. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">XIII. Privacy Policy Changes</p>
          <p>This Privacy Policy may be revised over time as new features are added to the Union Goldenpay (HK) Limited services or as we incorporate suggestions from our stakeholders. We may change this Privacy Policy at any time by posting a revised version of it on our website. We will provide you with at least 30 days' prior notice of the effective date of the revised Privacy Policy when it is legally required. We may post the notice on the front page of our website(s) and/or send the notice by e-mail, but we are under no obligation to do so. As of the effective date of the revised Privacy Policy, you will be considered as having consented to all changes to the Privacy Policy. If you disagree with the terms of this Privacy Policy, you may close your account at any time.
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">XIV. Liability</p>
          <p>We are not liable to you in respect of any claims, losses, damages, expenses (including reasonable legal fees) arising out of or in connection with the use and/or disclosure or dissemination of the user data in accordance with this privacy policy and any consents that you may have otherwise provided to us.
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">XV. Your Consent</p>
          <p>Your use of Union Goldenpay (HK) Limited services means that you accept this Privacy Policy. You agree that the personal information given by you and collected by us from time to time may be used and disclosed for the foregoing purposes, and to such persons as may be appropriate in accordance with this Privacy Policy.<br>

We expressly reserve the right to make changes to this Privacy Policy from time to time at our discretion without prior or separate notification. Any changes will be posted to our Website. Your continued use indicates your agreement to those changes.
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">XIV. How to contact us?</p>
          <p>If you have any questions or concerns about our use of your personal information, please contact us by email at cs@sprout-pay.com, or by post at Unit 21, 8/F, ADMIRALTY CENTRE TOWER 2, 18 HARCOURT ROAD, ADMIRALTY, HONG KONG.
          </p>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { reactive, toRefs, inject } from 'vue'
export default {
  name: 'PrivacyPolicyEN',
  setup () {
    const state = reactive({
      themeData: inject('$themeData')
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
p.navigationTitle{margin-top: 20px;font-size: 18px;color: black;font-weight: bold;}
</style>
